
.containerProducto{
    width: 320px;
    margin-bottom: 10px;
}

.producto-carrusel {
    display: flex;
    flex-direction: column;
    position: relative;
    z-index: 0;
    width: 100%;
    cursor: pointer;
    transition: all .3s ease-in-out;
}

.producto-carrusel img {
    width: 100%;
    height: 400px;
    object-fit: cover;
    border-radius: 20px;
}

.titleAnime-carrusel,
.titleProducto-carrusel,
.precioProducto-carrusel {
    margin-top: 0.7rem;
}

.titleAnime-carrusel {
    color: #6C6C6C;
    font-size: 1.3rem;
    font-weight: 300;
}

.titleProducto-carrusel {
    text-transform: uppercase;
    color: #6C6C6C;
    font-weight: bolder;
    font-size: 1.4rem;
    margin-bottom: 2rem;
}

.precioProducto-carrusel {
    text-transform: uppercase;
    color: #F67280;
    font-weight: bolder;
    font-size: 1.4rem;
    position: absolute;
    bottom: 0;
}

.stockProducto-carrusel {
    color: #6C6C6C;
    font-size: 1.3rem;
    font-weight: 300;
    position: absolute;
    bottom: 0;
    right: 0;
}

.productosContainer {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    gap: 10px;
}


@media screen and (max-width: 900px) {

    .titleAnime-carrusel {
        font-size: 0.9rem;
    }

    .titleProducto-carrusel {
        font-size: 1rem;
    }

    .containerProducto{
        width: 200px;
    }

    .stockProducto-carrusel{
        font-size: 1rem;
    }
}

@media screen and (max-width: 500px) {


    .productosContainer a{
        width: 45%;
    }

    .containerProducto{
        width: 45%;
    }

    .producto-carrusel img {
        height: 300px;
    }
}

