.formFiltros {
    display: flex;
    flex-direction: row;
}

.opcionArticulos,
.opcionOrdenar {
    display: flex;
    margin-right: 2rem;
    align-items: center;
}


.opcionArticulos input,
.opcionOrdenar select {
    border: none;
    background-color: #ffffff;
    margin-left: 1rem;
    width: 50px;
    padding: 0.6rem;
    font-weight: bold;
    text-align: center;
    border: 1px solid #d3d3d3;
    border-radius: 3px;
}

.opcionOrdenar select {
    width: 150px;
}


.containerProducto {
    width: 320px;
    margin-bottom: 10px;
}

.producto {
    display: flex;
    flex-direction: column;
    position: relative;
    z-index: 0;
    width: 100%;
    cursor: pointer;
    transition: all .3s ease-in-out;
}

.producto img {
    width: 100%;
    height: 300px;
    object-fit: cover;
    border-radius: 20px;
}

.titleAnime,
.titleProducto,
.precioProducto {
    margin-top: 0.7rem;
}

.titleAnime {
    color: #6C6C6C;
    font-size: 1.3rem;
    font-weight: 300;
}

.titleProducto {
    text-transform: uppercase;
    color: #6C6C6C;
    font-weight: bolder;
    font-size: 1.4rem;
    margin-bottom: 2rem;
}

.precioProducto {
    text-transform: uppercase;
    color: #F67280;
    font-weight: bolder;
    font-size: 1.4rem;
    position: absolute;
    bottom: 0;
}

.stockProducto {
    color: #6C6C6C;
    font-size: 1.3rem;
    font-weight: 300;
    position: absolute;
    bottom: 0;
    right: 0;
}

.withoutStock {
  color: #ac3838;
}

.productosContainer {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    gap: 10px;
    margin-top: 20px;
}

.custom-select {
    position: relative;
    width: 200px;
    max-width: 100%;
    font-size: 1rem;
    color: #000;
    margin-left: 1rem;
  }
  
  .select-button {
    width: 100%;
    font-size: 1rem;
    background-color: #fff;
    padding: 0.6rem 1rem;
    border: 1px solid #caced1;
    border-radius: 0.25rem;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .selected-value {
    text-align: left;
  }
  
  .arrow {
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 6px solid #000;
    transition: transform ease-in-out 0.3s;
  }
  
  .select-dropdown {
    position: absolute;
    list-style: none;
    width: calc(100% - 20px);
    box-shadow: 0 10px 25px rgba(0, 0, 0, 0.2);
    background-color: #fff;
    border: 1px solid #caced1;
    border-radius: 4px;
    padding: 10px;
    top: 23px;
    max-height: 250px;
    overflow-y: auto;
  
    transform: scaleY(0);
    opacity: 0;
    visibility: hidden;
    z-index: 9999;
  }
  
  .select-dropdown:focus-within {
    box-shadow: 0 10px 25px rgba(94, 108, 233, 0.6);
  }
  
  .rutaTitle {
    margin-top: 2rem;
  }

  .select-dropdown li {
    position: relative;
    cursor: pointer;
    display: flex;
    gap: 1rem;
    align-items: center;
    z-index: 9999;
  }
  
  .select-dropdown li label {
    width: 100%;
    padding: 20px 20px;
    cursor: pointer;
    display: flex;
    gap: 1rem;
    align-items: center;
  }
  
  .select-dropdown::-webkit-scrollbar {
    width: 7px;
  }
  .select-dropdown::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 25px;
  }
  
  .select-dropdown::-webkit-scrollbar-thumb {
    background: #ccc;
    border-radius: 25px;
  }
  
  .select-dropdown li:hover,
  .select-dropdown input:checked ~ label {
    background-color: #f2f2f2;
  }
  
  .select-dropdown input:focus ~ label {
    background-color: #dfdfdf;
  }
  
  .select-dropdown input[type="radio"] {
    position: absolute;
    left: 0;
    opacity: 0;
  }
  
  /* interactivity */
  
  .custom-select.active .arrow {
    transform: rotate(180deg);
  }
  
  .custom-select.active .select-dropdown {
    opacity: 1;
    visibility: visible;
    transform: scaleY(1);
  }
  

@media screen and (max-width: 900px) {

    .titleAnime {
        font-size: 0.9rem;
    }

    .titleProducto {
        font-size: 1rem;
    }

    .containerProducto {
        width: 200px;
    }

    .stockProducto {
        font-size: 1rem;
    }

    .formFiltros {

        flex-wrap: wrap;
    }

    .opcionArticulos,
    .opcionOrdenar {
        display: flex;
        margin-bottom: 1rem;
        align-items: center;
    }

    .producto img {
        height: 200px;
    }
}

@media screen and (max-width: 500px) {


    .productosContainer a {
        width: 45%;
    }

    .containerProducto {
        width: 45%;
    }
}

@media screen and (max-width: 380px) {

    .formFiltros {
        flex-direction: column;
    }

    .opcionArticulos,
    .opcionOrdenar {
        display: flex;
        margin-bottom: 1rem;
        align-items: center;
    }
}