.main{
	width: 400px;
	height: 500px;
	background: red;
	overflow: hidden;
	background-color: #21134e;
	border-radius: 10px;
	box-shadow: 5px 20px 50px #000;
	margin: auto;
	margin-top: 30px;
}
.chk{
	display: none;
}
.signup{
	position: relative;
	width:100%;
	height: 100%;
}
.description{
	color: #dddddd;
	margin: auto;
	width: 70%;
	text-align: center;
	margin-bottom: 50px;
}
.label{
	color: #fff;
	font-size: 2rem;
	justify-content: center;
	display: flex;
	margin: 60px;
	margin-bottom: 10px;
	font-weight: bold;
	cursor: pointer;
	transition: .5s ease-in-out;
}
.input{
	width: 70%;
	height: 20px;
	background: #e0dede;
	justify-content: center;
	display: flex;
	padding: 10px;
	border: none;
	outline: none;
	border-radius: 5px;
}
.inputContainer{
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	margin-top: 10px;
}
.btn{
	width: 70%;
	height: 40px;
	margin: 10px auto;
	justify-content: center;
	display: block;
	color: #fff;
	background: #573b8a;
	font-size: 1em;
	font-weight: bold;
	margin-top: 20px;
	outline: none;
	border: none;
	border-radius: 5px;
	transition: .2s ease-in;
	cursor: pointer;
}

.btn:hover{
	background: #6d44b8;
}

.login{
	height: 460px;
	background: #f2f2f2;
	border-radius: 60% / 10%;
	transform: translateY(-180px);
	transition: .8s ease-in-out;
}
.login label{
	color: #573b8a;
	transform: scale(.6);
}

.chk:checked ~ .login{
	transform: translateY(-500px);
}
.chk:checked ~ .login label{

	transform: scale(1);	
	transform: translateY(20px);
	margin-bottom: 50px;
}
.chk:checked ~ .signup label{
	transform: scale(.6);
}

.error{
	color: rgb(182, 42, 42);
	height: max-content;
	margin: auto;
	width: 70%;
	font-size: 0.8rem;
	margin-top: 5px;
}

@media screen and (max-width: 450px) {
    .main{
		width: 90%;
	}
}